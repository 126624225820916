export default {
    common: {
        area: '/uc/support/country'
    },
    uc: {
        login: '/user/login',
        register: '/user/register',
        wallet: '/user/getWithdrawalAddressList',
        identification: '/user/addWithdrawalAddress',
        mylistrecord: "/user/checkJbPassword", //待定
        activitylist: "/user/setJbPassword",
        personalWallet: "/user/getPersonalWallet",
        toppromotionmonth: "/user/logout",
        toppromotionweek: "/user/updatePassword", // 重置登录密码
        memberInfo: "/user/getPersonal", // 获取用户信息

        // submitHuioneName: '/user/updatePersonalHuioneName',//取消
        // submitRealName:   '/user/updatePersonalRealName',//取消
        // submitNickName: '/user/updatePersonalNickName',//取消
        // submitBirthday: '/user/updatePersonalBirthday',//取消
        // submitGender: '/user/updatePersonalGender', //取消
        // submitChange: '/user/setLastCcy',// 取消

        updatePersonalInfo: '/user/updateMem',
        announcement: '/user/resetPassword',
        myInnovationOrderList: "/user/updateJbPassword",


        captcha: '/sms/sendSmsCode',

        apply: '/thirdGame/downScore', // 待定
        myInnovationMinings: "/thirdGame/upScore",
        myInnovationLocked: "/thirdGame/getGameUrl",


        paydividends: "/funds/comSkAcctQuery", //待定
        memberactivity: "/funds/queryfunds",
        // attendActivity: "/funds/initiateAWithdrawal", // usdt 提现
        getfreecard: "/funds/withdrawVirtual", // 待定
        exchangecard: "/funds/transfer",
        submetExchange: '/funds/changeMoney',
        checkuser: "/funds/getUser",
        cambodiaWithdrawInit: '/funds/addWithdrawOrder',
        getDepositList: '/funds/getDepositList',
        getWithdrawList: '/funds/getWithdrawalList',

        toppromotion: "/app-notices/queryMarqueeAnnounce",
        mycardlist: "/app-ad-info/banner/list",

        udun: '/funds/getUdunAddress',
        joinList: '/app-activity/join/list',  //每次进主页请求，返回当前用户可参与活动
        receiveAward: '/app-activity/receiveAward',  //领取奖励

        agentData: "/app-agent/getAgentData",

        // mdboBalance: "/mdbo/getBalance", // 获取余额
        mdboGameList: "/mdbo/GetGameList", // 获取游戏列表
        mdboGameRecordList: "/game/getUserRecordList", // 获取游戏记录
        mdboLaunchGame: "/mdbo/LaunchGame", // 启动游戏
        // mdboMakeTransfer: "/mdbo/makeTransfer", // 拉取三方平台余额

        //柬埔寨支付
        cambodiaPayCb: '/campay/camCallback',
        cambodiaPayInit: '/campay/initDeposit',

        cambodiaPayRefresh: '/campay/transInfo',
        cambodiaWithdrawBanks: '/campay/withdrawBanks',
        cambodiaDepositBanks: '/campay/depositBanks',

        addDepositOrder: '/funds/addDepositOrder',


        cmdSbApi: '/cmd/createMember',
        cmdSbExistApi: '/cmd/exist',
        cmdMemberUpdate: '/cmd/updateMemberSetting',
        cmdLang: '/cmd/languageInfo',

        sbsbApi: '/saba/createMember',
        sabaUrl: '/saba/GetSabaUrl',

        rateList: '/funds/exchangeRateList',
        platform: '/game/getThirdPlatformList'

    },

    sports: {
        // /pp/getCasinoGames
        // get
        // String options（具体传参什么，参考PP文档）   获取游戏列表
        getCasinoGames: '/pp/getCasinoGames',  //获取游戏列表

        // /pp/getLobbyGames
        // get
        // String ：categories
        // String ：country（具体传参什么，参考PP文档）    获取大厅列表 :老虎机大厅配置的娱乐场游戏列表
        getLobbyGames: '/pp/getLobbyGames',

        // post
        // secureLogin，
        // token，
        // externalPlayerId    这三个参数不用传，其他的参数参考PP文档        获取游戏连接， 调用该接口之前先调用/user/updateMem   传参 remark，设置用户币种
        gameUrl: '/pp/game/url',

        // /pp/openHistory
        // get
        // String ：roundId
        // pp游戏记录id      获取游戏详情url
        openHistory: "/pp/openHistory",


        /*
        *    FB   http://bw99.tg86.org
        * */
        userCreate: '/fb/userCreate',
        //         h5服务地址，
        // /fb/userCreate    创建fb用户
        // get

        getToken: '/fb/getToken',
        //         // /fb/getToken  获取fb的token
        //         // get
        //         // String platForm 平台

        userDetail: '/fb/userDetail',
        // /fb/userDetail    获取fb的用户信息，用于判断是否已经在fb注册
        // get

        orderUrlList: '/fb/orderUrlList',
        // /fb/orderUrlList   获取fb的注单详情连接
        // get

        // Integer[] orderIds,  fb的注单id列表
        // String languageType 语言

        matchPage: '/fb/matchPage',
        // 获取fb的获取赛事和赔率数据   参数参考fb的这个接口的文档
        // /fb/matchPage
        // post
    }

}

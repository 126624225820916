module.exports = {
    common: {
        login1: 'មានគណនីរួចហើយឬ? ចូល',
        register: 'ចុះឈ្មោះ',
        logout: 'ចាកចេញ',
        tip: 'ក្រើនរំលឹក！！',
        logintip: 'សូមចុះឈ្មោះជាមុន',
        set: 'ការកំណត់',
        amount:"ទឹកប្រាក់",
        close: "បិទ",
        ok: "បញ្ជាក់",
        capitalPass: 'ព័ត៌មាន​ផ្ទាល់ខ្លួន',
        capitalPassinput: 'បញ្ចូលលេខសម្ងាត់ដកប្រាក់',
        realNameInput: 'គោត្តនាមកាតធនាគារ',
        huioneNameInput: 'គោត្តនាមហួយវ័ន្ត',
        huioneName: 'គណនីហួយវ័ន្ត',
        nicknameInput: 'ឈ្មោះហៅក្រៅ',
        usertip:'ផ្ទេរប្រាក់ជោគជ័យ',
        tibifirst:"ព័ត៌មានលម្អិត",
        message:"ព័ត៌មាន",
        service:'សេវាកម្មអតិថិជន JBbet',
        ads:'សកម្មភាព',
        aboutus:'អំពីពួកយើង',
        pleaseselectService: "ជម្រើស",
        pleaseselectCurrency: "រូបិយប័ណ្ណរបស់ខ្ញុំ",
        currencyType:'ជ្រើសរើសរូបិយប័ណ្ណ',
        pleaseselectService1: "របៀបដកប្រាក់",
        pleaseselectService2: "វីធីផ្ទេរ",
        tabbar1:'ទំព័រដើម',
        tabbar2:'សកម្មភាព',
        tabbar3:'សេវាអតិថិជន',
        tabbar4:'របស់ខ្ញុំ',
        date: 'សូម​ជ្រើសរើស​រយៈពេល',
        date1: 'សមាជិកក្រៅបណ្តាញ',
        date2: 'ចំនួនសរុបឈ្នះចាញ់',
        date3: 'ចំនួនដាក់ភ្នាល់សរុប',
        date4: 'គណនី',
        date5: 'ចំនួនដាក់ភ្នាល់',
        date6: 'ចាញ់ឈ្នះ',
        date7: 'ចំនួនដាក់ភ្នាល់ជោគជ័យសរុប',
        date8: 'ចំនួនដាក់ភ្នាល់ជោគជ័យ',
        date9: 'ភ្នាក់ងារ',
        date10: 'គណនីសមាជិក :',
exchange: 'ប្ដូរប្រាក់',

        notice23: 'ការរំលឹកដ៏កក់ក្តៅ៖ បច្ចុប្បន្នអ្នកលេងអាចផ្ទេរប្រាក់ទៅគណនីភ្នាក់ងារតែប៉ុណ្ណោះ សូមដឹង!',
        categorySport:'កីឡា',
        categoryLive:'ហ្គេម',
        categorySlot:'ហ្គេមស្លត',
        categoryFish:'បាញ់ត្រី',
        categoryBingo:'សន្លឹកឆ្នោត',
        categoryPoke:'ល្បែងអុក',
        gender: 'ភេទ',
        birthday: 'ថ្ងៃខែឆ្នាំកំណើត',
        nickname: 'ឈ្មោះហៅក្រៅ',
        male: 'ប្រុស',
        female: 'ស្រី',
        name: 'គោត្តនាម',
        usdt: 'USDT',
        usd: 'ដុល្លា',
        exchangeAll: 'ទាំងអស់',
        gOut: 'ផ្ទេរចេញ',
        goIn: 'ផ្ទេរចូល',
        meamount:'ទឹកប្រាក់',
        mebonus: 'ភាគលាភ',
        agSport: 'AG 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTIកីឡា',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'BBBIN 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: 'ចុះឈ្មោះ',
            usertip: 'លេខទូរស័ព្ទ',
            pwdtip: 'ពាក្យសម្ងាត់',
            validatecodeload: 'ពាក្យផ្ទៀងផ្ទាត់',
            validatemsg: 'សូមបំពេញការផ្ទៀងផ្ទាត់ជាមុនសិន',
            forget: 'ភ្លេចពាក្យសម្ងាត់',
            loginvalidate: 'គណនី',
            pwdvalidate1: 'ពាក្យសម្ងាត់',
            pwdvalidate2: 'ប្រវែង​ពាក្យ​សម្ងាត់​មិន​អាច​តិច​ជាង 6 តួអក្សរ',
            success: 'ចុះឈ្មោះជោគជ័យ',
            goregister: "ចុះឈ្មោះ",
            getlostpwd: "ភ្លេចពាក្យសម្ងាត់",
            nouser: "អ្នកប្រើប្រាស់មិនមានទេ។",
            normaluser:'គណនីរបស់ភាគីម្ខាងទៀតមិនគាំទ្រការផ្ទេរទេ។',
            slogan:'亚洲最信赖博彩平台之一',
            toSetting:'កែតម្រូវ',
            settinghuione:'ឈ្មោះអ្នកប្រើ HUIONE',
            settingbank:'ឈ្មោះអ្នកប្រើកាតធនាគារ'
        },
        regist: {
            smscode: 'ពាក្យផ្ទៀងផ្ទាត់',
            sendcode: 'ផ្ញើរពាក្យផ្ទៀងផ្ទាត់',
            resendcode: 'ផ្ញើរម្ដងទៀត(',
            pwd: 'ពាក្យសម្ងាត់ចុះឈ្មោះ',
            repwd: 'ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់',
            agreement: 'ខ្ញុំបានអាននិងយល់ព្រម',
            userprotocol: 'លក្ខខណ្ឌ និងកិច្ចព្រមព្រៀង',
            regist: 'ចុះឈ្មោះ',
            teltip: 'លេខទូរស័ព្ទ',
            telerr: 'សូមបញ្ចូលលេខទូរស័ព្ទដែលត្រឹមត្រូវ',
            emailtip: 'អ៊ីម៉ែល',
            emailerr: 'ពាក្យសម្ងាត់គណនីមិនត្រឹមត្រូវ សូមបញ្ចូលម្ដងទៀត',
            confirmpwdtip: 'ពាក្យសម្ងាត់ស្ទួន',
            confirmpwderr: 'ពាក្យសម្ងាត់មិនត្រឹមត្រូវ!',
            telregist: 'ការចុះឈ្មោះលេខទូរស័ព្ទចល័ត',
            emailregist: 'ចុះឈ្មោះតាមអ៊ីម៉ែល',
            smscodetip: 'ពាក្យផ្ទៀងផ្ទាត់',
            pwdtip: 'ពាក្យសម្ងាត់ចុះឈ្មោះ',
            pwdmsg: 'ពាក្យសម្ងាត់មិនត្រូវតិចជាង6ខ្ទង់',
            telno: 'លេខទូរស័ព្ទ',
            email: 'អ៊ីម៉ែល',
            agreementtip: 'សូមបញ្ជាក់',
            modaltitle: 'សូម​ផ្ទៀងផ្ទាត់',
            promotion: 'IDភ្នាក់ងារ(មិនចាំបាច់)',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'               
        },
        forget: {
            newpwd: 'ពាក្យសម្ងាត់ថ្មី',
            confirmpwd: 'ពាក្យសម្ងាត់ស្ទួន',
            confirmpwd11: 'គណនីសមាជិក',
            save: 'រក្សាទុក',
            pwdvalidate1: 'ពាក្យសម្ងាត់',
            pwdvalidate2: 'ពាក្យសម្ងាត់មិនត្រឹមត្រូវ!',
            resettelpwd: 'កែប្រែពាក្យសម្ងាត់ចុះឈ្មោះ',
            resetemailpwd: 'កែប្រែពាក្យសម្ងាត់ដកប្រាក់',
            newpwdtip: 'ពាក្យសម្ងាត់ថ្មី',
            pwdvalidate3: 'ប្រវែង​ពាក្យ​សម្ងាត់​មិន​អាច​តិច​ជាង 6 តួអក្សរ',
            smscode: 'ពាក្យផ្ទៀងផ្ទាត់',
            teltip: 'លេខទូរស័ព្ទ',
            smscodetip: 'ពាក្យផ្ទៀងផ្ទាត់',
            email: 'អ៊ីម៉ែល',
            emailcode: 'ពាក្យផ្ទៀងផ្ទាត់',
            emailtip: 'អ៊ីម៉ែល',
            emailcodetip: 'ពាក្យសម្ងាត់ដកប្រាក់',
            resetpwdsuccess: 'អាប់ដេតជោគជ័យ',
            addresstip: 'បំពេញអាសយដ្ឋានដកប្រាក់',
            addresstip21: 'គណនីសមាជិក',
            addresstip221: 'គណនី',
            addresstip222: 'គណនីធនាគារ',
            emailcodetip1: 'កំណត់ពាក្យសម្ងាត់ដកប្រាក់',
            emailcodetip11: 'បំពេញអាសយដ្ឋានដកប្រាក់',
            walletaddress: 'អាសយដ្ឋានគ្រប់គ្រង',
            huioneaddress: 'គណនីហួយវ័ន្ត',
            walletaddress1: 'អាសយដ្ឋានគ្រប់គ្រង',
            walletaddress2: 'ចំនួនទឹកប្រាក់ជាក់ស្តែងដែលទទួលបាន！',
            walletaddress21: 'ចំនួនទឹកប្រាក់ត្រូវតែច្រើនជាង 0！',
            wallet: 'កាបូប',
            userID: 'គណនីសមាជិក',
            upload: 'ផ្ទុកឡើងវិញ្ញាបនបត្រ',
            addresstip31: 'សូមបញ្ចូលឈ្មោះអ្នកបង់ប្រាក់ HUIONE',
            addresstip41: 'សូមបញ្ចូលលេខគណនីអ្នកបង់ប្រាក់ HUIONE',
            addresstip311: 'គោត្តនាម',
            addresstip321:'គោត្តនាម',
            personal: 'ព័ត៌មាន​ផ្ទាល់ខ្លួន'
        },
        finance: {
            center: 'ព័ត៌មានផ្ទាល់ខ្លួន',
            personalassets: 'បណ្តាញដាក់ប្រាក់/ដកប្រាក់',
            swapassets: 'ការគ្រប់គ្រងអាសយដ្ឋាន',
            swapassets1: 'គណនី',
            recordetail: 'ព័ត៌មានលម្អិតអំពីប្រតិបត្តិការ',
            tradetail: 'ចំនួនសរុប',
            invitingmining: 'ចំនួនទឹកប្រាក់',
            charge: 'បញ្ចូលលុយ',
            pickup: 'ដកប្រាក់',
            copy: 'ចម្លង',
            choose: 'ជ្រើសរើស',
            cards: 'ការគ្រប់គ្រងកាត',
            copysuccess:'ចម្លងដោយជោគជ័យ',
            copyfailed:'ការចម្លងបានបរាជ័យ',
            notice:"网络服务费/手续费由会员支付",
            notice1:"每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: 'ប្រសិនបើគណនីមិនត្រូវបានទទួលយូរទេ សូមទាក់ទងផ្នែកបម្រើអតិថិជន សម្រាប់ការប្រឹក្សាទាន់ពេល។',
            transfee:"ថ្លៃសេវាកម្ម",
            emailcodetip: 'លេខសម្ងាត់ដកប្រាក',
            gamedetail: 'កំណត់ត្រាហ្គេម',
            gametype:'ប្រភេទហ្គេម',
            game0: 'WL',
            game81: 'អ្នកចែកបៀរLive',
            game82: 'ហ្គេមល្បែងអុក',
            game83: '电子游戏',
            game84: 'ហ្គេមកីឡា',
            game85: 'ហ្គេមស្លុត',
            game86: 'សន្លឹកឆ្នោត',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WLកីឡា',
            bet:'ព័ត៌មានលម្អិតការភ្នាល់',
            validBet: 'ដាក់ភ្នាល់ជោគជ័យ',
            profit: 'ឈ្នះចាញ់',
            addUaddress: 'កាតធនាគារ',
            addaddress:'បំពេញអាសយដ្ឋានដកប្រាក់',
            trans: ' ផ្ទេរប្រាក់',
            agentdata: 'របាយការណ៍កំរៃជើងសារ',
            agentpage: 'សហការអាជីវកម្ម',
            timezone: 'កំណត់តំបន់ពេលវេលា',
            agentapply: 'ដាក់ស្នើរភ្លាម',
            agentnotice: 'ដាក់ពាក្យសុំភ្នាក់ងារ',
            datapage:'របាយការណ៍កំរៃជើងសារ',
            promotion: 'លទ្ធផលការផ្សព្វផ្សាយ',
            promotion1: 'ផ្សព្វផ្សាយកូដ QR',
            promotion2: 'រក្សាទុករូបភាព',
            promotion3: 'តំណភ្ជាប់ការផ្សព្វផ្សាយ',
            notice12:"បន្ទាប់ពីអាស័យដ្ឋានដកប្រាក់ត្រូវបានកំណត់ដោយជោគជ័យ វាអាចត្រូវបានកែប្រែដោយការទាក់ទងផ្នែកបម្រើអតិថិជនប៉ុណ្ណោះ។",
            notice123:"ផ្ទេរទៅគណនីសមាជិក",
            notice1234:"ប្រតិបត្តិការបានជោគជ័យ ហើយនឹងដំណើរការទាន់ពេល សូមរង់ចាំ។！",
            notice12345:"ដាក់ភ្នាល់ជោគជ័យ",
            xx1 : 'លេខបញ្ជីដាក់ប្រាក់',
            xx2 : 'លេខបញ្ជីដកប្រាក់',
            xx3 : 'ស្ថានភាព',
            xx4 : 'កំពុងត្រួតពិនិត្យ',
            xx5 : 'ជោគជ័យ',
            xx6 : 'បរាជ័យ',
            xx7 : 'ឈ្មោះ',
            xx8: 'គណនី',
            xx9 : 'គោត្តនាម',
            xx10 : 'គណនី',
            xx11 : 'ចំណាំ',
            xx12 : 'ពេលវេលា',
            xx13 : 'គណនីសមាជិក',
            xx14 : 'មិនទាន់មាន',
            xx15 : 'ផ្ទេរប្រាក់សមាជិក',
            xx17 : 'ការផ្ទេរ និងការដកប្រាក់',
            xx18 : 'ផ្ទេរ និងបញ្ចូលទឹកប្រាក់',
            xx19 : 'បញ្ចូលទឹកប្រាក់តាមអ៊ីនធឺណិត',
            xx20 : 'បញ្ចូលទឹកប្រាក់តាមអ៊ីនធឺណិត',
            xx21 : 'ការដក U-Shield！',
            xx22 : 'ដកលុយតាមបណ្ដាញអ៊ីនធឺណេត',
            xx23 : 'បញ្ចូលប្រាក់',
            xx24 : 'ដកប្រាក់',
            xx25 : 'ប្រាក់រង្វាន់',
            xx26 : 'បន្ថែមពិន្ទុ',
            xx27 : 'ដកពិន្ទុ',
            xx28 : 'ចុះឈ្មោះអាំងប៉ាវ',
            xx29 : 'លេខបញ្ជី',
            xx30 : 'រយះពេល',
            xx31 : 'ទឹកប្រាក់បន្ទាប់ពីប្រតិបត្តិការ！！',
            xx32 :'ភាគរយកម្រៃជើងសារ',
            xx33 :'សមាជិកជីនប៉ីជាទីគោរព',
            xx34: 'សមាជិកក្រៅបណ្ដាញ',
            xx35: 'សមាជិកថ្មីដែលបានចុះឈ្មោះប្រចាំខែ',
            xx36: 'សមាជិកសកម្មប្រចាំខែ',
            xx37: 'ចំនួនឈ្នះ​ចាញ់សុទ្ធប្រចាំខែ',
            xx38: 'ប្រាក់កំរៃភាគរយត្រឡប់',
            xx39: 'កំរៃជើងសារប្រចាំខែ',
            xx40: 'ផ្សព្វផ្សាយកូដ QR',
            xx41: 'កូដឈ្មោះប្រើប្រាស់ពិសេស',
            xx42: 'បញ្ជីសមាជិកក្រៅបណ្តាញ',
            xx43: 'របាយការណ៍កំរៃជើងសារ',
            xx44: 'ស្វែងរកគណនីសមាជិក',
            xx45: 'គណនីសមាជិក',
            xx46: 'ពេលវេលាចូលគណនីចុងក្រោយ',
            xx47: 'កាលបរិច្ឆេទ​ចុះបញ្ជី',
            xx48: 'កំរៃជើងសារប្រចាំឆមាស',
            xx49: 'សរុបឈ្នះចាញ់',
            xx50: 'បញ្ចុះតម្លៃសរុប',
            xx51: 'ប្រាក់រង្វាន់',
            xx52: 'ថ្លៃកាត់ទឹកទូទាត់ប្រាក់',
            xx53: 'ថ្លៃសេវាកម្មទូទាត់បា្រក់',
            xx54: 'កម្រៃជើងសារ VIP បន្ថែម',
            xx55: 'ប្រាក់រង្វាន់ VIP',
            xx56: 'អត្រាកំរៃជើងសារ',
            xx57: 'ព័ត៌មានលម្អិតអំពីការចេញវិក្កយបត្រ',
            xx58: 'កំរៃជើងសារប្រចាំឆមាស',
            xx59: 'ថ្លៃសេវា',
            xx60: 'គណនីដកប្រាក់',
            xx61: 'បញ្ចូលប្រាក់CAM',
            xx62: 'CAMដកប្រាក់',
            xx63 : 'កំពុងទូទាត់',
            xx64 : 'ការទូទាត់ជោគជ័យ',
            xx65 : 'បង់ប្រាក់បរាជ័យ',
            xx66 : 'រយះពេលបញ្ចប់វិក័យប័ត្រ',
            xx67 : 'របៀបដាក់ប្រាក់',
            xx68 : 'ប្រភេទរូបិយប័ណ្ណ',
            xx69 : 'របៀបដកប្រាក់',
            platform: 'ប្រភេទហ្គេម',
            bitStatus: 'ស្ថានភាព',
            xx70 : 'ការភ្នាល់ជោគជ័យ',
            xx71 : 'កំពុងប្រគួត',
            xx72 : 'ការភ្នាល់បរាជ័យ',
            xx73 : 'ពេលចាប់ផ្ដើម',
            xx74 : 'ពេលបញ្ចប់',
            xx75 : 'ពត៌មានលម្អិតការភ្នាល់',
            xx76 : 'លេខបញ្ជី',
            xx77 : 'គណនីសមាជិក',
            xx78 : 'លេខតុ',
            xx79 : 'លទ្ធផល',
            xx80 : 'លេខហ្គេម',
            xx81 : 'ផ្លាស់ប្ដូភាសា',
            xx82 : 'សូមស្វាគមន៍មកកាន់សេវាកម្មអតិថិជនជីនប៉ី',
            xx83 : 'HI, អតិថិជនជាទីគោរព',
            xx84 : 'ឈ្មោះធនាគារ,សេវាកម្មអតិថិជនជាភាសាអង់គ្លេស',
            xx85 : 'សេវាកម្មអតិថិជនជាភាសាវៀតណាម',
            xx86 : 'សេវាកម្មអតិថិជនជាភាសាចិន',
            xx87 : 'សេវាកម្មអតិថិជនជាភាសាអង់គ្លេស',
            xx88 : 'គោត្តនាមកាតធនាគារ',
            xx89 : 'គណនីធនាគារ',
            xx91 : 'ឈ្មោះធនាគារ',
            xx92 : 'សេវាកម្មអតិថិជន24h/7',
            xx93 : ' បំពេញពត៌មានធនាគារ',
            xx94 : 'សូមចងអាសយដ្ឋានដកប្រាក់ (TRC20) ដើម្បីធានាសុវត្ថិភាពនៃទឹកប្រាក់',
            xx95 : 'តើអ្នកប្រាកដថាចង់ត្រឡប់ទៅទំព័រដើមវិញទេ? ប្រាក់នៅក្នុងហ្គេមប្រសិនបើមិនត្រលប់មកវិញត្រឹមត្រូវទេ សូមប្រើមុខងារចុចទូទាត់ត្រឡប់  នៅលើទំព័ររបស់ខ្ញុំ',
            xx96 : 'ឈ្មោះលីក',
            xx97 : ' ក្រុមម្ចាស់ផ្ទះ',
            xx98 : 'ក្រុមភ្ញៀវ',
            xx99 : 'ពិន្ទុ',
            xx100 : 'ព័ត៌មានទាំងអស់គឺចាំបាច់',
            xx101: 'អំពីJBbet',
            xx102: 'JbbetIt is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. JBbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.。',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players.',
            xx105: 'JBbets products and services',
            xx106: 'In addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone。',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers。',
            xx109: 'live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBbetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes。',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games,...',
            xx115: 'fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique。',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing JBbet to international friends. Therefore, please contact customer service for advice and answers when needed。',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: 'រូបិយប័ណ្ណនេះមិនត្រូវបានគាំទ្រទេ។ សូមចូលទៅកាន់ទំព័រកាបូបដើម្បីប្តូរ ឬប្តូររូបិយប័ណ្ណ។',
            xx121: 'សូមបំពេញឈ្មោះកាតធនាគារ,ដូចដែលបានបង្ហាញខាងក្រោម',
            xx122: 'អាសយដ្ឋាន USDT(TRC20)',
            xx123: 'ការគ្រប់គ្រងអាសយដ្ឋាន',
            xx124: 'កំណត់ត្រាទាំងអស់។',
            xx125: 'កត់ត្រាបញ្ចូល​លុយ',
            xx126: 'កត់ត្រាការដកប្រាក់',
            xx127: 'បោះបង់',
            xx128: 'កំពុងដាក់ពាក្យ',
            xx129: 'បានបញ្ជាក់',
            xx130: 'បានបដិសេធរួចហើយ',
            xx131: 'កំណត់ត្រាកីឡា',
            xx132: 'កំណត់ត្រាហ្គេម',
            xx133: 'លទ្ធផល',
            xx134: 'បានបញ្ចប់',
            xx135: 'មិនទាន់ចប់ទេ។',
            xx136: 'បោះបង់',
            xx137: 'ម្សិលមិញ',
            xx138: 'ថ្ងៃនេះ',
            xx139: '7 ថ្ងៃចុងក្រោយ',
            xx140: '30 ថ្ងៃចុងក្រោយ',
            xx141: 'ភ្នាល់តែមួយ',
            xx142: 'ការភ្នាល់ច្រើន។',
            xx143: 'ជើងឯក',
            xx144: 'ជ្រើសកាលបរិច្ឆេទ',
            xx145 : 'English customer service',
            xx146 : 'លាក់',
            xx147 : 'ប្រាក់បញ្ញើសាកល្បង',
            xx148 : 'ព័ត៌មានលម្អិតការភ្នាល់',
            xx149 : 'CMD កីឡា',
            xx150 : 'កីឡា Sabah',
            xx151 : 'GamingSoft',
            xx152 : 'កាលបរិច្ឆេទប្រកួត',
            xx153 : 'USDT ផ្ទេរចេញ',
            xx154 : 'USDT ផ្ទេរចូល',
            xx155 : 'บริการลูกค้าไทย',
            xx156 : 'Layanan pelanggan indonesia',
            xx157 : 'JDB ស្លុត',
            xx158 : 'PG ស្លុត',
            xx159 : 'PP ស្លុត',
            xx160 : 'NT ស្លុត',
            xx161 : 'JDB ការនេសាទ',
            xx162 : 'សួស្តី សូមស្វាគមន៍ចំពោះការមកដល់របស់អ្នក។',
            xx163 : 'មិនមានគណនីមែនទេ?',
            xx164 : 'រូបិយប័ណ្ណរបស់ខ្ញុំ',
            xx165 : 'ដោះដូរ',
            xx166 : 'ចុះឈ្មោះស្រោមសំបុត្រក្រហម',
            xx167 : 'ការកែតម្រូវគណនីប្រព័ន្ធ CMD',
            xx168 : '游戏买断',
            xx169 : '游戏买入',
            xx170 : 'Jackpot រីកចម្រើន',
            xx171 : 'ប្រាក់រង្វាន់',
            xx172 : 'បោះបង់ការភ្នាល់',
            xx173 : '回滚投注',
            xx174 : 'ដំណោះស្រាយហ្គេម',
            xx175 : 'ល្បែងភ្នាល់',
            xx176 : 'សងប្រាក់វិញ ប្រសិនបើការដកប្រាក់បរាជ័យ',
            xx177 : 'ផ្ទេរប្រាក់សមាជិក',
            xx178 : 'ដកពិន្ទុ',
            xx179 : 'ភាគរយកម្រៃជើងសារ',
            xx180 : 'បន្ថែមពិន្ទុ',
            xx181 : 'ប្រាក់រង្វាន់',
            xx182 : 'បញ្ចូលប្រាក់CAM',
            xx183 : 'Huioneបញ្ចូលប្រាក់',
            xx184 : 'U-Walletបញ្ចូលប្រាក់',
            xx185 : 'បញ្ចូលទឹកប្រាក់តាមអ៊ីនធឺណិត',
            xx186 : 'ដកលុយតាមបណ្ដាញអ៊ីនធឺណេត',
            xx187 : 'Huioneដកប្រាក់',
            xx188 : 'U-Walletដកប្រាក់',
        },
    }
}

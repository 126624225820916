export default {
    
    JDB: {
        SLOT: '0',
        FISH: '7',
        ARCADE: '9',
        BINGO: '12',
        CARD: '18'
    },
    SPRIBE: {
      
        ARCADE: '22',
       
    },
    AMB: {
      
        SLOT: '50',
       
    },
    TP: {
      
        SLOT: '26',
        FISH: '27',
       
    },
    FC: {
      
        SLOT: '31',
        FISH: '32',
        ARCADE: '30',
    },
    SWG: {
      
        SLOT: '55',
      
        ARCADE: '56',
    },
    MANCALA: {
      
        SLOT: '57',
      
    },
    GTF: {
      
        SLOT: '66',
        FISH: '67',
    },
   
}

module.exports = {
    common: {
        login1: 'หากคุณได้ลงทะเบียนแล้ว กรุณาเข้าสู่ระบบ',
        register: 'ลงทะเบียนก่อน',
        logout: 'ออกจากระบบ',
        tip: 'โปรดทราบ',
        logintip: ' กรุณาเข้าสู่ระบบ',
        set: 'การตั้งค่',
        amount:"ตาชั่ง",
        close: "ออกจากระบบ",
        ok: "แน่นอน",
        capitalPass: 'ข้อมูลส่วนตัว',
        capitalPassinput: 'ตั้งค่ารหัสผ่านการถอนเงิน',
        realNameInput: 'ชื่อบัตรธนาคาร',
        huioneNameInput: 'HUIONEชื่อ',
        huioneName: 'HUIONEบัญชี',
        nicknameInput: 'ชื่อเล่น',
        usertip:'โอนสำเร็จ',
        tibifirst:"รายละเอียดก",
        message:"ข้อมูล",
        service:'JBbetบริการลูกค้า',
        ads:'กิจกรรม',
        aboutus:'เกี่ยวกับเรา',
        pleaseselectService: "เลือกวิธีการ",
        pleaseselectCurrency: "สกุลเงินของฉัน",
        currencyType:'เลือกสกุลเงิน',
        pleaseselectService1: "วิธีการถอนเงิน",
        pleaseselectService2: "วิธีการโอน",
        tabbar1:'หน้าหลัก',
        tabbar2:'กิจกรรม',
        tabbar3:'บริการลูกค้า',
        tabbar4:'ฉัน',
        date: 'โปรดเลือกช่วงเวลา',
        date1: 'สมาชิกดาวน์ไลน์',
        date2: 'ชนะและแพ้ทั้งหมด',
        date3:  'จำนวนเงินเดิมพันทั้งหมด  ',
        date4: 'บัญชี',
        date5: 'ยอดเงินเดิมพัน',
        date6: 'แพ้/ชนะ',
        date7: 'การเดิมพันที่ถูกต้อง',
        date8: 'การเดิมพันที่ถูกต้อง',
        date9: 'บัญชีพร็อกซี่',
        date10: 'บัญชีสมาชิก :',
exchange: 'แลก',

        notice23: 'คำเตือนที่อบอุ่น: ขณะนี้ผู้เล่นสามารถโอนเงินไปยังบัญชีตัวแทนเท่านั้น โปรดทราบ!',
        categorySport:'กีฬา',
        categoryLive:'คาสิโนสด',
        categorySlot:'สล็อต',
        categoryFish:'ยิงปลา',
        categoryBingo:'หวย',
        categoryPoke:'โป๊กเกอร์',
        gender: 'เพศ',
        birthday: 'วันเกิด',
        nickname: 'ชื่อ',
        male: 'ชาย',
        female: 'หญิง',
        name: 'ชื่อ',
        usdt: 'USDT',
        usd: 'ดอลลาร์',
        exchangeAll: 'ทั้งหมด',
        gOut: 'โอนออก',
        goIn: 'โอนเข้า',
        meamount:'ตาชั่ง',
        mebonus: 'แลกโบนัส',
        agSport: 'AG 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'BBIN 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: 'เข้าสู่ระบบ',
            usertip: 'โทรศัพท์',
            pwdtip: 'มรหัสผ่',
            validatecodeload:'รหัสยืนยัน',
            validatemsg: 'กรุณาทำการยืนยันให้เสร็จสิ้นก่อน',
            forget: 'ลืมรหัสผ่าน',
            loginvalidate: 'บัญชี',
            pwdvalidate1: 'รหัสผ่าน',
            pwdvalidate2: 'ความยาวรหัสผ่านต้องไม่น้อยกว่า 6 ตัวอักษร',
            success: 'เข้าสู่ระบบสำเร็จ',
            goregister: "ลงทะเบียนก่อน",
            getlostpwd: "ลืมรหัสผ่าน",
            nouser: "ไม่มีผู้ใช้อยู่",
            normaluser:'บัญชีของอีกฝ่ายไม่รองรับการโอนเงิน',
            slogan:'亚洲最信赖博彩平台之一',
            toSetting:'แก้ไข',
            settinghuione:'HUIONDชื่อผู้ใช้',
            settingbank:'ชื่อผู้ใช้บัตรธนาคาร',
        },
        regist: {
            smscode: 'รหัสยืนยัน',
            sendcode: 'ส่งรหัสยืนยัน',
            resendcode: 'ส่งซ้ำ',
            pwd: 'รหัสผ่านการเข้าใช้งาน',
            repwd: 'ยืนยันรหัสผ่าน',
            agreement: 'ข้าพเจ้าอายุครบ 18 ปีได้อ่านและยอมรับ',
            userprotocol: 'ข้อตกลงในการเปิดบัญชี',
            regist: 'ลงทะเบียนก่อน',
            teltip: 'หมายเลขโทรศัพท์',
            telerr: 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
            emailtip: 'จดหมาย',
            emailerr: 'รหัสบัญชีไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            confirmpwdtip: 'ทำซ้ำรหัสผ่าน',
            confirmpwderr: 'รหัสผ่านผิด!',
            telregist: 'การลงทะเบียนหมายเลขโทรศัพท์มือถือ',
            emailregist: 'การลงทะเบียนอีเมล',
            smscodetip: 'รหัสยืนยันทาง SMS',
            pwdtip: 'รหัสผ่านการเข้าใ',
            pwdmsg: 'ความยาวรหัสผ่านต้องไม่น้อยกว่า 6 ตัวอักษร',
            telno: 'โทรศัพท์',
            email: 'ที่อยู่อีเมล',
            agreementtip: 'กรุณายืนยัน',
            modaltitle: 'กรุณายืนยัน.',
            promotion: 'พร็อกซี่(非必填)',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'       
        },
        forget: {
            newpwd: 'รหัสผ่านใหม่',
            confirmpwd: 'ทำซ้ำรหัสผ่าน',
            confirmpwd11: 'บัญชีสมาชิก',
            save: 'ส่ง',
            pwdvalidate1: 'รหัสผ่าน',
            pwdvalidate2: 'รหัสผ่านผิด',
            resettelpwd: 'แก้ไขรหัสผ่าน',
            resetemailpwd: 'แก้ไขรหัสผ่านการถอน',
            newpwdtip: 'รหัสผ่านใหม่',
            pwdvalidate3: 'ความยาวรหัสผ่านต้องไม่น้อยกว่า 6 ตัวอักษร',
            smscode: 'รหัสยืนยันทาง SMS',
            teltip: 'หมายเลขโทรศัพท์',
            smscodetip: 'รหัสยืนยันทาง SMS',
            email: 'จดหมาย',
            emailcode: 'รหัสยืนยันทาง SMS',
            emailtip: 'จดหมาย',
            emailcodetip: 'รหัสผ่านการชำระเงิน',
            resetpwdsuccess: 'การอัปเดตเสร็จสมบูรณ์',
            addresstip: 'แก้ไขรหัสผ่านการถอน',
            addresstip21: 'บัญชีสมาชิก',
            addresstip221: 'บัญชี',
            addresstip222: 'บัญชี',
            emailcodetip1: 'ตั้งค่ารหัสผ่านการถอนเงิน',
            emailcodetip11: 'เพิ่มที่อยู่การถอน',
            walletaddress: 'การจัดการที่อยู่',
            huioneaddress: 'HUIONEบัญชี',
            walletaddress1: 'การจัดการที่อยู่',
            walletaddress2: 'จำนวนเงินที่ได้รับจริง',
            walletaddress21: 'จำนวนต้องมากกว่า 0！',
            wallet: 'กระเป๋าเงิน',
            userID: 'บัญชีสมาชิก',
            upload: 'คลิกเพื่ออัพโหลดใบรับรอง！',
            addresstip31: 'HUIONEชื่อ',
            addresstip41: 'HUIONEบัญชี',
            addresstip311: 'ชื่อ',
            addresstip321:'ชื่อ',
            personal: 'ข้อมูลส่วนตัว',
        },
        finance: {
            center: 'ข้อมูลส่วนตัว',
            personalassets: 'เครือข่ายการฝาก/ถอนเงิน',
            swapassets: 'การจัดการที่อยู่',
            swapassets1: 'บัญชี',
            billdetail:'รายละเอียดธุรกรรม',
            recordetail: 'รายละเอียดธุรกรรม',
            tradetail: 'สมดุล',
            invitingmining: 'ปริมาณ',
            charge: 'ฝากเงิน',
            pickup: 'ถอนเงิน',
            copy: 'สำเนา',
            choose: 'เลือก',
            cards: 'การจัดการบัตร',
            copysuccess:'คัดลอกเรียบร้อยแล้ว',
            copyfailed:'การคัดลอกล้มเหลว',
            notice:"网络服务费/手续费由会员支付",
            notice1:"每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: 'หากบัญชีของคุณไม่ได้รับเครดิตมาเป็นเวลานาน โปรดติดต่อฝ่ายบริการลูกค้าของหน้าแรกเพื่อขอคำปรึกษา',
            transfee:"ค่าธรรมเนียมการจัดการ",
            emailcodetip: 'การตั้งค่ารหัสผ่าน',
            gamedetail: 'ประวัติการเล่น',
            gametype:'ประเภทเกม',
            game0: 'WL',
            game81: 'คาสิโนสด',
            game82: 'โป๊กเกอร์',
            game83: 'เกมอิเล็กทรอนิกส์',
            game84: 'การแข่งขันกีฬา',
            game85: 'สล็อต',
            game86: 'หวย',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WLกีฬา',
            bet:'รายละเอียดการเดิมพัน',
            validBet: 'การเดิมพันที่ถูกต้อง',
            profit: 'ชนะ',
            addUaddress: 'บัญชี',
            addaddress:'เพิ่มที่อยู่การถอน',
            trans: 'โอนย้าย',
            agentdata: 'งบคอมมิชชั่น',
            agentpage: 'พันธมิตร',
            timezone: 'การตั้งค่าโซนเวลา',
            agentapply: 'ลงทะเบียนเลย',
            agentnotice: 'กรุณาสมัครตัวแทนก่อน',
            datapage:'งบคอมมิชชั่น',
            promotion: 'ประสิทธิภาพการส่งเสริม',
            promotion1: 'โปรโมตรหัส QR',
            promotion2: 'บันทึกรูปภาพ',
            promotion3: 'ลิงก์ส่งเสริมการขาย',
            notice12:"หลังจากตั้งค่าที่อยู่ถอนเหรียญสำเร็จแล้ว จะสามารถแก้ไขได้โดยติดต่อฝ่ายบริการลูกค้าเท่านั้น",
            notice123:"โอนเข้าบัญชีสมาชิก",
            notice1234:"ประมวลผลเรียบร้อยแล้ว กรุณารอสักครู่",
            notice12345:'การเดิมพันที่ถูกต้อง',
            xx1 : 'คำสั่งฝากเงิน',
            xx2 : 'เลขที่คำสั่งถอนเงิน',
            xx3 : 'สภาพ',
            xx4 : 'ทบทวน',
            xx5 : 'ความสำเร็จ',
            xx6 : 'ล้มเหลว',
            xx7 : 'ชื่อ',
            xx8: 'บัญชี',
            xx9 : 'ชื่อ',
            xx10 : 'บัญชี',
            xx11 : 'คำอธิบายประกอบ',
            xx12 : 'เวลา',
            xx13 : 'บัญชีสมาชิก',
            xx14 : 'ยัง',
            xx15 : 'การโอนสมาชิก',
            xx17 : 'โอนและถอนเงินสด',
            xx18 : 'โอนและเติมเงิน',
            xx19 : 'เติมเงินออนไลน์',
            xx20 : 'เติมเงินออนไลน์',
            xx21 : 'การถอน U-Shield',
            xx22 : 'การถอนเงินออนไลน์',
            xx23 : 'ฝากเงิน',
            xx24 : 'ถอนเงิน',
            xx25 : 'เงินรางวัล',
            xx26 : 'เพิ่มคะแนน',
            xx27 : 'หักคะแนนแล้ว',
            xx28 : 'ทะเบียนอั่งเปา',
            xx29 : 'หมายเลขคำสั่งซื้อ',
            xx30 : 'เวลา',
            xx31 : 'ยอดคงเหลือหลังการทำธุรกรรม',
            xx32 :'เปอร์เซ็นต์คอมมิชชั่น',
            xx33 :'Jinbeiเรียนสมาชิกทุกท่าน',
            xx34: 'สมาชิกดาวน์ไลน์',
            xx35: 'สมาชิกใหม่ที่ลงทะเบียนในเดือนนี้',
            xx36: 'สมาชิกที่ใช้งานอยู่ในเดือนนี้',
            xx37: 'ขาดทุนสุทธิสําหรับเดือน',
            xx38: 'เปอร์เซ็นต์เงินคืน',
            xx39: 'ค่าคอมมิชชั่นเดือนนี้',
            xx40: 'โปรโมตรหัส QR',
            xx41: 'ชื่อโดเมนส่วนตัว',
            xx42: 'รายชื่อสมาชิกดาวน์ไลน์',
            xx43: 'งบคอมมิชชั่น',
            xx44: 'ค้นหาบัญชีสมาชิก',
            xx45: 'Akaun Ahli',
            xx46: 'เวลาเข้าสู่ระบบครั้งล่าสุด',
            xx47: ' วันที่ลงทะเบียน',
            xx48: 'คอมมิชชั่นสําหรับงวดปัจจุบัน',
            xx49: 'ชนะและแพ้ทั้งหมด',
            xx50: 'ยอดรวมเงินคืน',
            xx51: 'เงินรางวัล',
            xx52: 'ค่าธรรมเนียมส่วนลดการไหลของทองคํา',
            xx53: 'ค่าธรรมเนียมการไหลทอง',
            xx54: 'คอมมิชชั่นพิเศษ VIP',
            xx55: 'เงินรางวัลวีไอพี',
            xx56: 'เปอร์เซ็นต์คอมมิชชั่น %',
            xx57: 'ตารางสรุปฐานะการเงิน',
            xx58: 'คอมมิชชั่นสําหรับงวดปัจจุบัน',
            xx59: 'ค่าธรรมเนียมแพลตฟอร์ม',
            xx60: 'บัญชีถอนเงิน',
            xx61: 'cam เติม',
            xx62: 'cam สารสกัด',
            xx63 : 'กำลังดำเนินการ',
            xx64 : 'การชำระเงินสำเร็จ',
            xx65 : 'การชำระเงินล้มเหลว',
            xx66 : 'เวลาเสร็จสิ้นการสั่งซื้อ',
            xx67 : 'วิธีการฝากเงิน',
            xx68 : 'เงินตรา',
            xx69 : 'วิธีการถอนเงิน',
            platform: 'แพลตฟอร์มเกม',
            bitStatus: 'สถานะ',
            xx70 : 'การเดิมพันที่ถูกต้อง',
            xx71 : 'เกมดังกล่าวเปิดอยู่',
            xx72 : 'ไม่มีการเดิมพัน',
            xx73 : 'เวลาเริ่มต้น',
            xx74 : 'หมดเวลา',
            xx75 : 'รายละเอียดการเดิมพัน',
            xx76 : 'เลข',
            xx77 : 'ชื่อสมาชิก',
            xx78 : 'จำนวนโต๊ะ',
            xx79 : 'ผลการแข่งขัน',
            xx80 : 'รหัสเกม',
            xx81 : 'เปลี่ยนภาษา',
            xx82 : 'ยินดีต้อนรับสู่การบริการลูกค้าของ jbbet',
            xx83 : 'เรียนลูกค้า',
            xx84 : 'บริการลูกค้าภาษาอังกฤษเขมร',
            xx85 : 'การบริการลูกค้าเวียดนาม',
            xx86 : 'บริการลูกค้าชาวจีน',
            xx87 : 'บริการลูกค้าภาษาอังกฤษ',
            xx88 : 'ชื่อบัตรธนาคาร',
            xx89 : 'หมายเลขบัตรธนาคาร',
            xx91 : 'ชื่อบัตรธนาคาร',
            xx92 : 'บริการลูกค้า',
            xx93 : 'เพิ่มบัญชีธนาคาร',
            xx94 : 'โปรดผูกที่อยู่การถอนเงิน (TRC20) เพื่อรับรองความปลอดภัยของเงินทุน',
            xx95 : 'คุณแน่ใจหรือไม่ว่าต้องการกลับไปที่ล็อบบี้? หากเงินในเกมไม่ได้รับการส่งคืนอย่างถูกต้อง โปรดใช้ฟังก์ชันการคืนสินค้าในคลิกเดียวบนหน้าของฉัน',
            xx96 : 'ชื่อลีก',
            xx97 : 'ทีมเหย้า',
            xx98 : 'ทีมเยือน',
            xx99 : 'คะแนน',
            xx100 : 'ข้อมูลทั้งหมดเป็นสิ่งจำเป็น',
            xx101: 'เกี่ยวกับJBbet',
            xx102: 'JBbetIt is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. Jbbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players',
            xx105: 'JBbet’s products and services',
            xx106: 'Ln addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers',
            xx109: 'Live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBbetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games',
            xx115: 'Fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing Jbbet to international friends. Therefore, please contact customer service for advice and answers when needed',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: 'ไม่รองรับสกุลเงินนี้ โปรดไปที่หน้ากระเป๋าเงินเพื่อแลกเปลี่ยนหรือเปลี่ยนสกุลเงิน',
            xx121: 'กรุณากรอกชื่อบัตรธนาคารของคุณดังที่แสดงด้านล่าง',
            xx122: 'ที่อยู่ USDT(TRC20)',
            xx123: 'การจัดการที่อยู่',
            xx124: 'บันทึกทั้งหมด',
            xx125: 'บันทึกการเติมเงิน',
            xx126: 'บันทึกการถอนเงิน',
            xx127: 'ยกเลิก',
            xx128: 'ในคําขอ',
            xx129: 'ได้รับการยืนยัน',
            xx130: 'ถูกปฏิเสธ',
            xx131: 'บันทึกกีฬา',
            xx132: 'บันทึกเกม',
            xx133: 'ผลลัพธ์',
            xx134: 'ปิดแล้ว',
            xx135: 'ยังไม่จบ',
            xx136: 'ยกเลิก',
            xx137: 'เมื่อวาน',
            xx138: 'วันนี้',
            xx139: 'เกือบ 7 วัน',
            xx140: 'เกือบ 30 วัน',
            xx141: 'เดิมพันเดี่ยว',
            xx142: 'รายการ',
            xx143: 'แชมป์',
            xx144: 'วันที่เลือกเอง',
            xx145 : 'English customer service',
            xx146 : 'ซ่อน',
            xx147 : 'ทดสอบการเติมเงิน',
            xx148 : 'การเดิมพันเกม',
            xx149 : 'CMDพลศึกษา',
            xx150 : 'Sabaพลศึกษา',
            xx151 : 'GamingSoft',
            xx152 : 'วันที่แข่งขัน',
            xx153 : 'โอนออก',
            xx154 : 'โอนเข้า',
            xx155 : 'บริการลูกค้าไทย',
            xx156 : 'Layanan pelanggan indonesia',
            xx157 : 'JDB สล็อตแมชชีน',
            xx158 : 'PG สล็อตแมชชีน',
            xx159 : 'PP สล็อตแมชชีน',
            xx160 : 'NT สล็อตแมชชีน',
            xx161 : 'JDB ตกปลา',
            xx162 : 'HI, ยินดีต้อนรับ',
            xx163 : 'อย่ามีบัญชีหรือยัง?',
            xx164 : 'สกุลเงินของฉัน',
            xx165 : 'แลกเปลี่ยน',
            xx166 : 'ทะเบียนอั่งเปา',
            xx167 : 'CMDการประสานงานของระบบ',
            xx168 : '游戏买断',
            xx169 : '游戏买入',
            xx170 : 'แจ็คพอตแบบก้าวหน้า',
            xx171 : 'โบนัส',
            xx172 : 'ยกเลิกเดิมพัน',
            xx173 : '回滚投注',
            xx174 : 'การตั้งถิ่นฐานเกม',
            xx175 : 'เกมเดิมพัน',
            xx176 : 'การถอนล้มเหลวและส่งคืน',
            xx177 : 'การโอนสมาชิก',
            xx178 : 'หักคะแนนแล้ว',
            xx179 : 'เปอร์เซ็นต์คอมมิชชั่น',
            xx180 : 'เพิ่มคะแนน',
            xx181 : 'เงินรางวัล',
            xx182 : 'CAMเติม',
            xx183 : 'Huione เติม',
            xx184 : 'Udunเติม',
            xx185 : 'เติมเงินออนไลน์',
            xx186 : 'การถอนเงินออนไลน์',
            xx187 : 'Huione ถอน',
            xx188 : 'Udunถอน',
        },
    }
}

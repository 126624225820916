import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    member: null,
    lang: '',
    exchangeSkin:'night',
    loginTimes: null,
    mobileTo:'',
    code:'',
    isThird:0,
    orderNo:'',
    paymethod:'',
    amount:0,
    cytype:'',
    isGoWC:false
},
  mutations: {
    setlang(state, lang) {
      state.lang = lang;
      localStorage.setItem('LANGUAGE', lang);
  },
    setMember(state, member) {
      state.member = member;
      localStorage.setItem('MEMBER', JSON.stringify(member));
     
  },
  setMobile(state, mobile) {
    state.mobileTo = mobile;
},
setOrderNo(state, orderNo) {
  state.orderNo = orderNo;
},
setPayMethod(state, pay) {
  state.paymethod = pay;
},
setAmount(state, amt) {
  state.amount = amt;
},
setCurrency(state, cyytype) {
  state.cytype = cyytype;
},
setCode(state, code) {
  state.code = code;
},
setThird(state, third) {
  state.isThird = third;
},
setIsGoWC(state, isnow) {
  state.isGoWC = isnow;
},
  },
  actions: {
  },
  modules: {
  },
  getters: {
    member(state) {
        return state.member||localStorage.getItem('MEMBER');
    },
 
    isLogin(state) {
        console.log("sdfsfsdfcccc"+state.member);
        console.log("sdfsdf  "+localStorage.getItem('TOKEN'));
        let val = localStorage.getItem('TOKEN')
        let xx = false
        if (val === null || val === undefined || val === ''|| val === 'null') {
          xx = false
        } else {
          xx = true
        }
         return xx
    },
    lang(state) {
        return state.lang || localStorage.getItem('LANGUAGE');
    },
    loginTimes(state) {
        return state.loginTimes;
    },
    mobileTo(state){
      return state.mobileTo;
    },
    code(state){
      return state.code;
    },
    isThird(state){
      return state.isThird
    },
    isGoWC(state){
      return state.isGoWC
    },
    orderNo(state){
      return state.orderNo
    },
    payMethod(state){
      return state.paymethod
    },
    amount(state){
      return state.amount
    },
    currencyType(state){
      return state.cytype
    },

}
})

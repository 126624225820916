module.exports = {
    common: {
        login1: 'Sudah memiliki akun? Gabung',
        register: 'Daftar',
        logout: 'Keluar',
        tip: 'Peringatan',
        logintip: 'Silahkan login',
        set: 'Pengaturan',
        amount:"Saldo",
        close: "Berhenti",
        ok: "Ok",
        capitalPass: 'Profil',
        capitalPassinput: 'Pengaturan Sandi Penarikan',
        realNameInput: 'Nama kartu bank',
        huioneNameInput: 'HUIONENama',
        huioneName: 'HUIONEakun',
        nicknameInput: 'Nama Panggilan',
        usertip:'Selesaikan transfer',
        tibifirst:"Detail",
        message:"Informasi",
        service:'JBbetConsultant',
        ads:'Promosi',
        aboutus:'Tentang kami',
        pleaseselectService: "Pilihan",
        pleaseselectCurrency: "Mata Uang Saya",
        currencyType:'Pilih mata uang',
        pleaseselectService1: "Metode penarikan",
        pleaseselectService2: "Cara mentransfer uang",
        tabbar1:'Beranda',
        tabbar2:'Promosi',
        tabbar3:'Consultant',
        tabbar4:'Saya',
        date: 'Silakan pilih jangka waktu',
        date1: 'Anggota Downline',
        date2: 'Total W/L',
        date3: 'Total Taruhan',
        date4: 'Akun',
        date5: 'Taruhan',
        date6: 'Memenangkan',
        date7: 'Total Taruhan yang valid',
        date8: 'Taruhan yang valid',
        date9: ' Proxy',
        date10: 'Akun Anggota :',
exchange: 'Pertukaran ',

        notice23: 'Pengingat hangat: Saat ini pemain hanya dapat mentransfer uang ke rekening agen, perlu diketahui !',
        categorySport:'Sports',
        categoryLive:'Casino',
        categorySlot:'Slots',
        categoryFish:'Fishing',
        categoryBingo:'Slots',
        categoryPoke:'Poker',
        gender: 'Jenis Kelamin',
        birthday: 'Tanggal Lahir',
        nickname: 'Nama Panggilan',
        male: 'Laki-Laki',
        female:'Perempuan',
        name: 'Nama',
        usdt: 'USDT',
        usd: 'Dolar ',
        exchangeAll: 'Semua',
        gOut: 'Transfer keluar',
        goIn: 'Transfer masuk',
        meamount:'Saldo',
        mebonus: 'Bonus',
        agSport: 'AG 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'BBIN 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: 'Login',
            usertip: 'Nomor Telepon ',
            pwdtip: 'Sandi ',
            validatecodeload: 'Kode verifikasi',
            validatemsg: 'Silakan selesaikan verifikasi terlebih dahulu',
            forget: 'Lupa Kata Sandi',
            loginvalidate: 'Akun',
            pwdvalidate1: 'kata sandi',
            pwdvalidate2: 'Panjang kata sandi tidak boleh kurang dari 6 karakter',
            success: 'login berhasil',
            goregister: "Daftar",
            getlostpwd: "Lupa Kata Sandi",
            nouser: "Pengguna tidak ada",
            normaluser:'Rekening pihak lain tidak mendukung transfer.',
            slogan:'亚洲最信赖博彩平台之一',
            toSetting:'修改',
            settinghuione:'HUIONEakun',
            settingbank:'Nama kartu bank'
        },
        regist: {
            smscode: 'Kode verifikasi SMS   ',
            sendcode: 'Kode verifikasi',
            resendcode: 'Kirim ulang(',
            pwd: 'kata sandi login',
            repwd: 'konfirmasi sandi',
            agreement: 'Saya   dan telah membaca dan setuju',
            userprotocol: 'Perjanjian Pembukaan Akun',
            regist: 'Daftar',
            teltip: 'Nomor Telepon',
            telerr: 'Masukkan nomor telepon yang benar',
            emailtip: 'Surat',
            emailerr: 'Username atau password salah, silahkan masukkan kembali',
            confirmpwdtip: 'Ulangi Kata Sandi',
            confirmpwderr: 'Kesalahan!',
            telregist: 'Daftarkan telepon Anda',
            emailregist: 'Registrasi email',
            smscodetip: 'Kode verifikasi',
            pwdtip: 'kata sandi login',
            pwdmsg: 'Panjang kata sandi tidak boleh kurang dari 6 karakter',
            telno: 'Nomor Telepon',
            email: 'Alamat email',
            agreementtip: 'Mohon konfirmasi',
            modaltitle: 'Harap verifikasi',
            promotion: 'Identitas agen（非必填）',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'       
        },
        forget: {
            newpwd: 'kata sandi baru',
            confirmpwd: 'Ulangi Kata Sandi',
            confirmpwd11: 'Akun Anggota',
            save: 'Kirim',
            pwdvalidate1: 'Sandi ',
            pwdvalidate2: 'Password salah!',
            resettelpwd: 'Ubah Kata Sandi',
            resetemailpwd: 'Ubah Kata Sandi Penarikan',
            newpwdtip: 'Kata sandi baru',
            pwdvalidate3: 'Panjang kata sandi tidak boleh kurang dari 6 karakter',
            smscode: 'Kode verifikasi',
            teltip: 'Kode verifikasi',
            smscodetip: 'Kode verifikasi',
            email: 'Surat',
            emailcode: 'Kode verifikasi',
            emailtip: 'Surat',
            emailcodetip: 'Sandi Penarikan',
            resetpwdsuccess: 'Pembaruan selesai',
            addresstip: 'Tambahkan Alamat Penarikan',
            addresstip21: 'Akun Anggota',
            addresstip221: 'HUIONEakun',
            addresstip222: 'Nomor Akun',
            emailcodetip1: 'Pengaturan Sandi Penarikan',
            emailcodetip11: 'Tambahkan Alamat Penarikan',
            walletaddress: 'Manajemen Alamat',
            huioneaddress: 'HUIONEakun',
            walletaddress1: 'Manajemen Alamat',
            walletaddress2: 'Jumlah aktual yang diterima！',
            walletaddress21: 'Kuantitas harus >0！',
            wallet: 'Saya',
            userID: 'Akun Anggota',
            upload: 'Klik untuk mengunggah kredensial！',
            addresstip31: 'HUIONENama',
            addresstip41: 'HUIONEakun',
            addresstip311: 'Nama',
            addresstip321:'Nama',
            personal: 'Profil'
        },
        finance: {
            center: 'Profil',
            personalassets: '입출금 네트워크！',
            swapassets: 'Manajemen Alamat',
            swapassets1: 'Akun',         
            recordetail: 'Catatan Transaksi',
            tradetail: 'Saldo',
            invitingmining: 'Jumlah',
            charge: 'Deposit',
            pickup: 'Penarikan',
            copy: 'Menyalin',
            choose: 'Pilih',
            cards: 'Kartu',
            copysuccess:'Berhasil disalin',
            copyfailed:'Penyalinan gagal',
            notice:"网络服务费/手续费由会员支付",
            notice1:"每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: 'Jika akun Anda sudah lama tidak dikreditkan, silakan hubungi layanan pelanggan di halaman beranda untuk konsultasi.',
            transfee:"Biaya penanganan",
            emailcodetip: 'Sandi Penarikan',
            gamedetail: 'Games',
            gametype:'Tipe permainan',
            game0: 'WL',
            game81: 'Live Dealer',
            game82: 'Poker-QQ',
            game83: '电子游戏',
            game84: 'Permainan olahraga',
            game85: 'Slots',
            game86: 'Lotre',
            game87: '捕鱼',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WLSports',
            bet:'Detail Taruhan',
            validBet: 'Taruhan yang valid',
            profit: 'Memenangkan',
            addUaddress: 'Kartu bank',
            addaddress:'Tambahkan Alamat Penarikan',
            trans: 'Transfer',
            agentdata: 'Laporan komisi',
            agentpage: 'Kemitraan',
            timezone: 'Pengaturan Zona Waktu',
            agentapply: 'Apply sekarang',
            agentnotice: 'Lamar ke agensi',
            datapage:'Laporan komisi',
            promotion: 'Efektivitas promosi',
            promotion1: 'Promosikan kode QR',
            promotion2: 'Simpan Gambar',
            promotion3: 'Tautan promosi',
            notice12:"Setelah alamat penarikan koin berhasil diatur, alamat tersebut hanya dapat diubah dengan menghubungi layanan pelanggan.",
            notice123:"Transfer ke akun anggota",
            notice1234:"Operasi berhasil dan akan diproses tepat waktu, harap tunggu.！",
            notice12345:"Taruhan yang valid",
            xx1 : 'Nomor pesanan setoran',
            xx2 : 'Nomor pesanan penarikan',
            xx3 : 'Status',
            xx4 : 'Ditinjau',
            xx5 : 'Berhasil',
            xx6 : 'Gagal',
            xx7 : 'Nama',
            xx8: 'Akun',
            xx9 : 'Nama',
            xx10 : 'Akun',
            xx11 : 'Keterangan',
            xx12 : 'Waktu',
            xx13 : 'Akun Anggota',
            xx14 : 'Belum ada',
            xx15 : 'AnggotaTransfer',
            xx17 : 'Transfer dan tarik tunai',
            xx18 : 'Transfer dan isi ulang',
            xx19 : 'Isi ulang daring',
            xx20 : 'Isi ulang daring',
            xx21 : 'Penarikan U-Shield',
            xx22 : 'Penarikan online',
            xx23 : 'Deposit',
            xx24 : 'Penarikan',
            xx25 : 'Bonus',
            xx26 : 'Tambahkan poin',
            xx27 : 'Poin dikurangi',
            xx28 : 'Nomor Pesanan',
            xx29 : 'Jumlah order',
            xx30 : 'Waktu',
            xx31 : 'Saldo setelah transaksi',
            xx32 :'Tarif komisi',
            xx33 :'Anggota Jinbei yang terhormat',
            xx34: 'Anggota Downline',
            xx35: 'Anggota baru mendaftar bulan ini',
            xx36: 'Anggota aktif bulan ini',
            xx37: 'Menang/Kalah Bersih Bulan Ini',
            xx38: 'Rasio Rabat',
            xx39: 'Komisi bulan ini',
            xx40: 'Promosikan kode QR',
            xx41: 'Nama domain pribadi',
            xx42: 'Daftar Anggota Downline',
            xx43: 'Laporan komisi',
            xx44: 'Cari akun ',
            xx45: 'Akun Anggota',
            xx46: 'Waktu logon terakhir',
            xx47: 'Tanggal Pendaftaran',
            xx48: 'Komisi saat ini',
            xx49: 'Total W/L',
            xx50: 'Total Rabat',
            xx51: 'Bonus',
            xx52: 'Potongan Administrasi',
            xx53: 'Biaya aliran emas',
            xx54: 'Komisi Ekstra VIP',
            xx55: 'Bonus VIP',
            xx56: 'Tarif komisi',
            xx57: 'Tabel ringkasan keuangan',
            xx58: 'Komisi saat ini',
            xx59: 'Biaya platform',
            xx60: 'Akun penarikan',
            xx61: 'camDeposit',
            xx62: 'camPenarikan',
            xx63 : 'Membayar',
            xx64 : 'Pembayaran berhasil',
            xx65 : 'Pembayaran gagal',
            xx66 : 'Waktu penyelesaian pesanan',
            xx67 : 'Metode isi ulang',
            xx68 : 'Mata Uang',
            xx69 : 'Beranda',
            platform: 'Platform permainan',
            bitStatus: 'Status pertaruhan',
            xx70 : 'Taruhan yang valid',
            xx71 : 'Permainan sedang berlangsung',
            xx72 : 'Taruhan tidak valid',
            xx73 : 'Waktu mulai',
            xx74 : 'Menyelesaikanwaktu',
            xx75 : 'Detail taruhan',
            xx76 : 'Nomor Pesanan',
            xx77 : 'PemainID',
            xx78 : 'Jumlah tabel',
            xx79 : 'Hasil',
            xx80 : 'Kode permainan',
            xx81 : 'Bahasa',
            xx82 : 'Selamat datang di layanan pelanggan JBBET',
            xx83 : 'HI, Pelanggan yang terhormat',
            xx84 : 'Khmer, layanan pelanggan Inggris',
            xx85 : 'Layanan pelanggan Vietnam',
            xx86 : 'Layanan pelanggan Cina',
            xx87 : 'Layanan pelanggan bahasa Inggris',
            xx88 : 'Nama kartu bank',
            xx89 : 'Nomor Akun',
            xx91 : 'Nama Bank',
            xx92 : 'Layanan 24/7',
            xx93 : 'Tambah Kartu Bank',
            xx94 : 'Harap ikat alamat penarikan (TRC20) untuk memastikan keamanan dana',
            xx95 : 'Apakah Anda yakin ingin kembali ke lobi? Jika mata uang game Anda tidak dikembalikan secara normal, silakan gunakan fungsi pengembalian dana sekali klik di halaman saya.',
            xx96 : 'Nama liga',
            xx97 : 'Tim tuan rumah',
            xx98 : 'Tim tandang',
            xx99 : 'Pecahan',
            xx100 : 'Semua informasi diperlukan',
            xx101: 'TentangJBbet',
            xx102: 'JBbet It is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. JBbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players.',
            xx105: 'JBbets products and services',
            xx106: 'In addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone。',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers。',
            xx109: 'Live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBbetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes。',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games,...',
            xx115: 'Fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique。',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing Jbbet to international friends. Therefore, please contact customer service for advice and answers when needed。',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: 'Mata uang ini tidak didukung. Silakan buka halaman dompet untuk menukar atau mengubah mata uang.',
            xx121: 'Silakan isi nama kartu bank Anda, seperti gambar di bawah ini',
            xx122: 'Alamat USDT(TRC20)',
            xx123: 'Manajemen Alamat ',
            xx124: 'Semua catatan',
            xx125: 'Catatan isi ulang',
            xx126: 'Riwayat penarikan',
            xx127: 'Membatalkan',
            xx128: 'Menerapkan',
            xx129: 'Dikonfirmasi',
            xx130: 'Ditolak',
            xx131: 'Catatan olahraga',
            xx132: 'Catatan permainan',
            xx133: 'Hasil',
            xx134: 'TOelah berakhir',
            xx135: 'Belum berakhir',
            xx136: 'Membatalkan',
            xx137: 'Kemarin',
            xx138: 'Hari ini',
            xx139: '7 hari terakhir',
            xx140: '30 hari terakhir',
            xx141: 'Taruhan tunggal',
            xx142: 'Bertaruh pada banyak acara',
            xx143: 'Juara',
            xx144: 'tanggal pilihan Anda',
        xx145 : 'English customer service',
            xx146 : 'bersembunyi',
            xx147 : 'Setoran percobaan',
            xx148 : 'Bet Details',
            xx149 : 'Olahraga CMD',
            xx150 : 'Olahraga Saba',
            xx151 : 'GamingSoft',
            xx152 : 'tanggal pertandingan',
            xx153 : 'transfer keluar USDT ',
            xx154 : 'transfer masuk USDT ',
            xx155 : 'บริการลูกค้าไทย',
            xx156 : 'Layanan pelanggan indonesia',
            xx157 : 'JDB Slot',
            xx158 : 'PG Slot',
            xx159 : 'PP Slot',
            xx160 : 'NT Slot',
            xx161 : 'JDB menangkap ikan',
            xx162 : 'Halo selamat datang',
            xx163 : 'Belum punya akun?',
            xx164 : 'mata uang saya',
            xx165 : 'menukarkan',
            xx166 : 'Daftarkan amplop merah',
            xx167 : 'CMD Rekonsiliasi sistem',
            xx168 : '游戏买断',
            xx169 : '游戏买入',
            xx170 : 'jackpot progresif',
            xx171 : 'bonus',
            xx172 : 'Batalkan taruhan',
            xx173 : '回滚投注',
            xx174 : 'penyelesaian permainan',
            xx175 : 'Taruhan permainan',
            xx176 : 'Pengembalian dana jika penarikan gagal',
            xx177 : 'AnggotaTransfer',
            xx178 : 'Poin dikurangi',
            xx179 : 'Tarif komisi',
            xx180 : 'Tambahkan poin',
            xx181 : 'Bonus',
            xx182 : 'CAM Deposit',
            xx183 : 'Huione Deposit',
            xx184 : 'Udun Deposit',
            xx185 : 'Online Deposit',
            xx186 : 'Penarikan online',
            xx187 : 'Huione Penarikan ',
            xx188 : 'Udun Penarikan ',
        },
    }
}

module.exports = {
    common: {
        login1: 'Already have an account? Go log in！',
        register: 'Sign Up',
        logout: 'Log Out',
        tip: 'Reminder',
        logintip: 'please login',
        set: 'Settings',
        amount: "Balance",
        close: "Out",
        ok: "OK",
        capitalPass: 'Profile',
        capitalPassinput: 'Withdrawal Password Settings',
        realNameInput: 'Bank card name',
        huioneNameInput: 'HUIONE Name',
        huioneName: 'HUIONE Account',
        nicknameInput: 'Nickname',
        usertip: 'complete the deposit',
        tibifirst: "Detail",
        message: "Message",
        service: 'JBbet Services',
        ads: 'Promotions',
        aboutus: 'About Us',
        pleaseselectService: "Select Payment Method",
        pleaseselectCurrency: "My Currency",
        currencyType: 'Select Currency',
        pleaseselectService1: "Withdrawal method",
        pleaseselectService2: "Transfer method",
        tabbar1: 'Home',
        tabbar2: 'Promotions',
        tabbar3: 'Services',
        tabbar4: 'Me',
        tabbar5: 'Me',
        date: 'Please select a time period',
        date1: 'Downline Member',
        date2: 'Total W/L',
        date3: 'Total bet amount',
        date4: 'Account',
        date5: 'Total bet amount',
        date6: 'lose/Win',
        date7: 'Total Valid Bets',
        date8: 'Valid Bet',
        date9: 'Affiliate',
        date10: 'Member Account :',
        exchange: 'Exchange',

        notice23: 'Warm reminder: Currently players can only transfer money to agent accounts, please know!',
        categorySport: 'Sports',
        categoryLive: 'Live ',
        categorySlot: 'Slots',
        categoryFish: 'Fishing',
        categoryBingo: 'Lottery',
        categoryPoke: 'Poker',
        gender: 'Gender',
        birthday: 'Birthday',
        nickname: 'Nickname',
        male: 'M',
        female: 'F',
        name: 'Name',
        usdt: 'USDT',
        usd: 'Dollar',
        exchangeAll: 'all',
        gOut: 'Transfer out',
        goIn: 'Transfer in',
        meamount: 'Balance',
        mebonus: 'Bonus',
        agSport: 'AG 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'BBIN 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: 'Log in',
            usertip: 'Phone number',
            pwdtip: 'password',
            validatecodeload: 'Verification code',
            validatemsg: 'Please complete verification first',
            forget: 'Forgot Password',
            loginvalidate: 'Account',
            pwdvalidate1: 'password',
            pwdvalidate2: 'Password length cannot be less than 6 characters',
            success: 'Login successful',
            goregister: "Sign Up",
            getlostpwd: "Forgot Password",
            nouser: "User does not exist",
            normaluser: 'The other party’s account does not support transfers',
            slogan: '亚洲最信赖博彩平台之一',
            toSetting: 'Modify',
            settinghuione: 'HUIONE Name',
            settingbank: 'Bank card user name'
        },
        regist: {
            smscode: 'Verification code',
            sendcode: 'Send Code',
            resendcode: 'Resend(',
            pwd: 'Login Password',
            repwd: 'Confirm Password',
            agreement: 'I have read and agree',
            userprotocol: 'Terms and Conditions ',
            regist: 'Sign Up',
            teltip: 'Phone number',
            telerr: 'Please enter a valid phone number',
            emailtip: 'Mail',
            emailerr: 'Incorrect account name or password, please re-enter',
            confirmpwdtip: 'Confirm Password',
            confirmpwderr: 'Incorrect password!',
            telregist: 'Register your phone',
            emailregist: 'email registration',
            smscodetip: 'Verification code',
            pwdtip: 'Login Password',
            pwdmsg: 'Password length cannot be less than 6 characters',
            telno: 'Phone number',
            email: 'Mail',
            agreementtip: 'Please confirm',
            modaltitle: 'Please verify',
            promotion: 'AffiliateID(非必填)',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'
        },
        forget: {
            newpwd: 'New Password',
            confirmpwd: 'Confirm Password',
            confirmpwd11: 'Member Account',
            save: 'submit',
            pwdvalidate1: 'Password',
            pwdvalidate2: 'Incorrect password!',
            resettelpwd: 'Change Password',
            resetemailpwd: 'Modify Withdrawal Password',
            newpwdtip: 'New Password',
            pwdvalidate3: 'Password length cannot be less than 6 characters',
            smscode: 'Verification code',
            teltip: 'Phone number',
            smscodetip: 'Verification code',
            email: 'Mail',
            emailcode: 'Verification code',
            emailtip: 'Mail',
            emailcodetip: 'Withdrawal Password',
            resetpwdsuccess: 'Update completed',
            addresstip: 'Address Management',
            addresstip21: 'Member Account',
            addresstip221: 'Account',
            addresstip222: 'Account No',
            emailcodetip1: 'Withdrawal Password Settings',
            emailcodetip11: 'Address Management',
            walletaddress: 'Address Management',
            huioneaddress: 'HUIONE Account',
            walletaddress1: 'Address Management',
            walletaddress2: 'Actual amount received',
            walletaddress21: 'Amount must be > 0',
            wallet: 'Wallet',
            userID: 'Account',
            upload: 'Click to upload the receipt',
            addresstip31: 'HUIONE Name',
            addresstip41: 'HUIONE Account',
            addresstip311: 'Name',
            addresstip321: 'Name',
            personal: 'Profile'
        },
        finance: {
            center: 'Profile',
            personalassets: 'Deposit/Withdrawal Network',
            swapassets: 'Address Management',
            swapassets1: 'Account',
            recordetail: 'Transaction Records',
            tradetail: 'Balance',
            invitingmining: 'Amount',
            charge: 'Deposit',
            pickup: 'Withdrawal',
            copy: 'Copy',
            choose: 'Select',
            cards: 'Cards',
            copysuccess: 'Copied successfully',
            copyfailed: 'Copy failed',
            notice: "Handling fee/手续费由会员支付",
            notice1: "每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: 'If the account has not been received for a long time, please contact customer service for consultation in time.',
            transfee: "Transaction Fee",
            emailcodetip: 'Withdrawal Password',
            gamedetail: 'Games',
            gametype: 'game type',
            game0: 'WL',
            game81: 'Live Casino',
            game82: 'Poker',
            game83: '电子游戏',
            game84: 'Sports competition',
            game85: 'Slots',
            game86: 'LOTTERY',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WL Sports',
            bet: 'Bet Details',
            validBet: 'Valid Bets',
            profit: 'W/L',
            addUaddress: 'bank card',
            addaddress: 'Add Withdrawal Address',
            trans: 'Transfer',
            agentdata: 'Commission reports',
            agentpage: 'Affiliate',
            timezone: 'Timezone Settings',
            agentapply: 'Apply now',
            agentnotice: 'Apply for agency',
            datapage: 'Commission reports',
            promotion: 'Promote QR code',
            promotion1: 'Promotion Links',
            promotion2: 'Save Picture',
            promotion3: 'Promotion Links',
            notice12: "After the coin withdrawal address is successfully set, it can only be modified by contacting customer service.",
            notice123: "Transferred Member's Account",
            notice1234: "The operation is successful and will be processed in time, please wait.！",
            notice12345: "Valid Bets",
            xx1: 'Recharge order',
            xx2: 'Withdraw order',
            xx3: 'Status',
            xx4: 'Under review',
            xx5: 'success',
            xx6: 'Fail',
            xx7: 'Name',
            xx8: 'Account',
            xx9: 'Name',
            xx10: 'Account',
            xx11: 'Status',
            xx12: 'Time',
            xx13: 'Account',
            xx14: 'None yet',
            xx15: 'Member transfer',
            xx17: 'Transfer and withdraw cash',
            xx18: 'Transfer and recharge！！',
            xx19: 'Internet Deposit',
            xx20: 'Internet Deposit',
            xx21: 'U wallet withdrawal',
            xx22: 'Online Withdrawal',
            xx23: 'Deposit',
            xx24: 'Withdraw',
            xx25: 'Bonus',
            xx26: 'Make up points',
            xx27: 'Points deducted',
            xx28: 'Register red envelope',
            xx29: 'Order No.',
            xx30: 'time',
            xx31: 'Balance after transaction',
            xx32: 'Commission Rate',
            xx33: 'Dear Jinbei members',
            xx34: 'Downline Member',
            xx35: 'New Members This Month',
            xx36: 'Active Members This Month',
            xx37: 'This Months Net Win/Loss',
            xx38: 'Rebate Rate',
            xx39: 'Commission this month',
            xx40: 'Promote QR code',
            xx41: 'Dedicated Domain Name',
            xx42: 'Downline Member List',
            xx43: 'Commission reports',
            xx44: 'Search account',
            xx45: 'Member Account',
            xx46: 'Last Login',
            xx47: 'Register date',
            xx48: 'This Periods Commission',
            xx49: 'Total W/L',
            xx50: 'Total Rebate',
            xx51: 'Bonus',
            xx52: 'Administrative Deduction',
            xx53: 'Transaction Fee',
            xx54: 'VIP Extra Bonus',
            xx55: 'VIP Bonus',
            xx56: 'Commission Rate',
            xx57: 'Financial Report',
            xx58: 'This Periods Commission',
            xx59: 'Platform fees',
            xx60: 'Withdraw account',
            xx61: 'cam Deposit',
            xx62: 'cam Withdraw',
            xx63: 'Processing',
            xx64: 'payment successful',
            xx65: 'Payment failed',
            xx66: 'Bill completion time',
            xx67: 'Deposit method',
            xx68: 'Currency',
            xx69: 'Withdrawal method',
            platform: 'Gaming platform',
            bitStatus: 'Status',
            xx70: 'Valid Bets',
            xx71: 'The game is in progress',
            xx72: 'Invalid bet',
            xx73: 'Starting time',
            xx74: 'End Time',
            xx75: 'Betting details',
            xx76: 'Order No',
            xx77: 'Account',
            xx78: 'Table number',
            xx79: 'Results',
            xx80: 'Game code',
            xx81: 'Language',
            xx82: 'Welcome to JBbet customer service',
            xx83: 'HI, Dear customers',
            xx84: 'Cambodian,English customer service',
            xx85: 'Vietnam customer service',
            xx86: 'Chinese customer service',
            xx87: 'English customer service',
            xx88: 'Bank card name',
            xx89: 'Account No',
            xx91: 'Bank name',
            xx92: '24/7 Services',
            xx93: 'Add bank card',
            xx94: 'Please bind the withdrawal address (TRC20) to ensure the safety of funds',
            xx95: 'Are you sure you want to return to the lobby? If the game currency is not returned correctly, please use the one-click refund function on my page',
            xx96: 'League name',
            xx97: 'home team',
            xx98: 'away team',
            xx99: 'Fraction',
            xx100: 'All information is required',
            xx101: 'About JBbet',
            xx102: 'JBbetIt is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. JBbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.。',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players.',
            xx105: 'JBbet products and services',
            xx106: 'In addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone。',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers。',
            xx109: 'Live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes。',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games,...',
            xx115: 'Fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique。',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing JBbet to international friends. Therefore, please contact customer service for advice and answers when needed。',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: 'This currency is not supported. Please go to the wallet page to exchange or switch currencies.',
            xx121: 'Please fill in your bank card name, as shown below',
            xx122: 'USDT address (TRC20)',
            xx123: 'Address Management',
            xx124: 'All records',
            xx125: 'Recharge record',
            xx126: 'Withdrawal history',
            xx127: 'Cancel',
            xx128: 'Applying',
            xx129: 'Confirmed',
            xx130: 'Has been rejected',
            xx131: 'Sports records',
            xx132: 'Game records',
            xx133: 'Result',
            xx134: 'Closed',
            xx135: 'Not end yet',
            xx136: 'Cancel',
            xx137: 'Yesterday',
            xx138: 'Today',
            xx139: 'Last 7 days',
            xx140: 'Nearly 30 days',
            xx141: 'Single bet',
            xx142: 'Multiple games',
            xx143: 'Champion',
            xx144: 'Date of your choice',
            xx145: 'English customer service',
            xx146: 'hide',
            xx147: 'Test deposit',
            xx148: 'Bet Details',
            xx149: 'CMD Sports',
            xx150: 'Saba Sports',
            xx151: 'GamingSoft',
            xx152: 'Match date',
            xx153: 'USDT transfer out',
            xx154: 'USDT transfer in',
            xx155: 'บริการลูกค้าไทย',
            xx156: 'Layanan pelanggan indonesia',
            xx157: 'JDB Slots',
            xx158: 'PG Slots',
            xx159: 'PP Slots',
            xx160: 'NT Slots',
            xx161: 'JDB Flshing',
            xx162: 'HI, Welcome to your arrival',
            xx163: 'Don’t have an account?',
            xx164: 'My currency',
            xx165: 'Exchange',
            xx166: 'Register red envelope',
            xx167: 'CMD System reconciliation',
            xx168: '游戏买断',
            xx169: '游戏买入',
            xx170: 'Progressive jackpot',
            xx171: 'Bonus',
            xx172: 'Cancel bet',
            xx173: '回滚投注',
            xx174: 'Game settlement',
            xx175: 'Game betting',
            xx176: 'Refund if withdrawal fails',
            xx177: 'Member transfer',
            xx178: 'Points deducted',
            xx179: 'Commission Rate',
            xx180: 'Make up points',
            xx181: 'Bonus',
            xx182: 'CAM Deposit ',
            xx183: 'Huione Deposit',
            xx184: 'Udun Deposit',
            xx185: 'Manual Deposit',
            xx186: 'Manual Withdrawal',
            xx187: 'Huione Withdrawal',
            xx188: 'Udun Withdrawal',
        },
    }
}

module.exports = {
    common: {
        login1: '已有账号? 登录！！',
        register: '注册',
        logout: '退出登录',
        tip: '温馨提示！！',
        logintip: '请先登录',
        set: '设置',
        amount:"余额",
        close: "退出",
        ok: "确定",
        capitalPass: '个人资料',
        capitalPassinput: '提现密码设置',
        realNameInput: '银行卡姓名',
        huioneNameInput: '汇旺姓名',
        huioneName: '汇旺账号',
        nicknameInput: '昵称',
        usertip:'完成转账',
        tibifirst:"详情",
        message:"信息",
        service:'金贝体育客服',
        ads:'活动',

        pleaseselectService: "选择方式",
        pleaseselectCurrency: "我的货币",
        currencyType:'选择币种',
        pleaseselectService1: "提款方式",
        pleaseselectService2: "转账方式",
        tabbar1:'首页',
        tabbar2:'活动',
        tabbar3:'客服',
        tabbar4:'我的',
        date: '请选择时间段',
        date1: '下线会员',
        date2: '总输赢',
        date3: '总投注额',
        date4: '账号',
        date5: '投注额',
        date6: '输赢',
        date7: '总有效投注',
        date8: '有效投注',
        date9: '代理',
        date10: '会员账号 :',
exchange: '兑换',

        notice23: '温馨提示：目前玩家只能向代理账户转账, 请知悉 !!',
        categorySport:'体育',
        categoryLive:'真人',
        categorySlot:'老虎机',
        categoryFish:'捕鱼',
        categoryBingo:'彩票',
        categoryPoke:'棋牌',
        gender: '性別',
        birthday: '生日',
        nickname: '昵称',
        male: '男',
        female: '女',
        name: '姓名',
        usdt: 'USDT',
        usd: '美元',
        exchangeAll: '全部',
        gOut: '转出',
        goIn: '转入',
        meamount:'余额',
        mebonus: '红利',
        agSport: 'CMD 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'PP 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: '登 录',
            usertip: '手机号',
            pwdtip: '密码',
            validatecodeload: '验证码',
            validatemsg: '请先完成验证',
            forget: '忘记密码',
            loginvalidate: '账号',
            pwdvalidate1: '密码',
            pwdvalidate2: '密码长度不能少于6位',
            success: '登录成功',
            goregister: "注 册",
            getlostpwd: "忘记密码",
            nouser: "用户不存在",
            normaluser:'对方账号不支持转账',
            slogan:'亚洲最信赖博彩平台之一',
            toSetting:'修改',
            settinghuione:'请先设置汇旺用户名！！',
            settingbank:'请先设置银行卡用户名！！'
        },
        regist: {
            smscode: '验证码',
            sendcode: '发送验证码',
            resendcode: '重新发送(',
            pwd: '登录密码',
            repwd: '确认密码',
            agreement: '我已阅读并同意',
            userprotocol: '条款和协议',
            regist: '注册',
            teltip: '手机号',
            telerr: '请输入正确手机号',
            emailtip: '邮箱',
            emailerr: '账户密码有误，请重新输入',
            confirmpwdtip: '重复密码',
            confirmpwderr: '密码错误!',
            telregist: '手机注册',
            emailregist: '邮箱注册',
            smscodetip: '验证码',
            pwdtip: '登录密码',
            pwdmsg: '密码长度不能少于6位',
            telno: '手机号',
            email: '邮箱',
            agreementtip: '请确认',
            modaltitle: '请核实',
            promotion: '代理ID(非必填)',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'          
        },
        forget: {
            newpwd: '新密码',
            confirmpwd: '重复密码',
            confirmpwd11: '会员账号',
            save: '提交',
            pwdvalidate1: '密码',
            pwdvalidate2: '密码错误!',
            resettelpwd: '修改登录密码',
            resetemailpwd: '修改提现密码',
            newpwdtip: '新密码',
            pwdvalidate3: '密码长度不能少于6位',
            smscode: '验证码',
            teltip: '手机号',
            smscodetip: '验证码',
            email: '邮箱',
            emailcode: '验证码',
            emailtip: '邮箱',
            emailcodetip: '提现密码',
            resetpwdsuccess: '更新成功',
            addresstip: '添加提现地址',
            addresstip21: '会员账号',
            addresstip221: '账号',
            addresstip222: '银行卡号',
            emailcodetip1: '提现密码设置',
            emailcodetip11: '添加提现地址',
            walletaddress: '地址管理',
            huioneaddress: '汇旺账号',
            walletaddress1: '地址管理',
            walletaddress2: '实际到账金额！！',
            walletaddress21: '金额必须大于0！！',
            wallet: '钱包',
            userID: '会员账号',
            upload: '点击上传凭证！！',
            addresstip31: '汇旺付款者姓名',
            addresstip41: '汇旺付款者账号',
            addresstip311: '姓名',
            addresstip321:'姓名',
            personal: '个人资料'
        },
        finance: {
            center: '个人资料',
            personalassets: '充/提币网络！！',
            swapassets: '地址管理',
            swapassets1: '账号',
            billdetail: '交易明细',
            recordetail: '交易明细',
            tradetail: '余额',
            invitingmining: '金额',
            charge: '充值',
            pickup: '提现',
            copy: '复制',
            choose: '选取',
            cards: '卡片管理',
            copysuccess:'复制成功',
            copyfailed:'复制失败',
            notice:"网络服务费/手续费由会员支付",
            notice1:"每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: '如遇长时间未到账请及时联系主页客服进行咨询',
            transfee:"手续费",
            emailcodetip: '提现密码',
            gamedetail: '游戏记录',
            gametype:'游戏类型',
            game0: '瓦力游戏',
            game81: '真人荷官',
            game82: '棋牌游戏',
            game83: '电子游戏',
            game84: '体育游戏',
            game85: '老虎机',
            game86: '彩票',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: '瓦力体育',
            bet:'投注详情',
            validBet: '有效投注',
            profit: '输赢',
            addUaddress: '银行卡',
            addaddress:'添加提现地址',
            trans: '转账',
            agentdata: '佣金报表',
            agentpage: '商务合作',
            timezone: '时区设置',
            agentapply: '立刻申请',
            agentnotice: '请先申请代理！！',
            datapage:'佣金报表',
            promotion: '推广效果',
            promotion1: '推广二维码',
            promotion2: '保存图片',
            promotion3: '推广链接',
            notice12:"提币地址设置成功以后只能通过联系客服修改",
            notice123:"转入会员账号",
            notice1234:"操作成功，会及时处理，请稍等！！",
            notice12345:"有效投注",
            xx1 : '存款订单号',
            xx2 : '提现订单号',
            xx3 : '状态',
            xx4 : '审核中',
            xx5 : '成功',
            xx6 : '失败',
            xx7 : '姓名',
            xx8: '账号',
            xx9 : '姓名',
            xx10 : '账号',
            xx11 : '备注',
            xx12 : '时间',
            xx13 : '会员账号',
            xx14 : '暂无',
            xx15 : '会员转账',
            xx17 : '转账及提取',
            xx18 : '转账及充值',
            xx19 : '在线充值',
            xx20 : '在线充值',
            xx21 : 'U盾提现！！',
            xx22 : '在线提款',
            xx23 : '充值',
            xx24 : '提现',
            xx25 : '彩金',
            xx26 : '补分',
            xx27 : '扣分',
            xx28 : '注册红包',
            xx29 : '单号',
            xx30 : '时间',
            xx31 : '交易后余额！！',
            xx32 :'佣金比例',
            xx33 :'尊敬的金贝会员',
            xx34: '下线会员',
            xx35: '本月新注册会员',
            xx36: '本月活跃会员',
            xx37: '本月净输赢',
            xx38: '返佣比例',
            xx39: '本月佣金',
            xx40: '推广二维码',
            xx41: '专用域名',
            xx42: '下线会员列表',
            xx43: '佣金报表',
            xx44: '搜索会员账号',
            xx45: '会员账号',
            xx46: '最后登录时间',
            xx47: '注册日期',
            xx48: '本期佣金',
            xx49: '总输赢',
            xx50: '返点总计',
            xx51: '彩金',
            xx52: '金流折损费',
            xx53: '金流手续费',
            xx54: 'VIP额外佣金',
            xx55: 'VIP彩金',
            xx56: '佣金比例',
            xx57: '财务总表',
            xx58: '本期佣金',
            xx59: '平台费用',
            xx60: '提现账户',
            xx61: 'cam充值',
            xx62: 'cam提现',
            xx63 : '支付中',
            xx64 : '支付成功',
            xx65 : '支付失败',
            xx66 : '账单完成时间',
            xx67 : '存款方式',
            xx68 : '币种',
            xx69 : '提现方式',
            platform: '游戏平台',
            bitStatus: '状态',
            xx70 : '有效注单',
            xx71 : '比赛进行中',
            xx72 : '无效注单',
            xx73 : '开始时间',
            xx74 : '结束时间',
            xx75 : '投注详情',
            xx76 : '单号',
            xx77 : '会员账号',
            xx78 : '桌号',
            xx79 : '赛果',
            xx80 : '游戏代码',
            xx81 : '语言变更',
        },
    }
}

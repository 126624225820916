<template>
  <div id="app">
    <!-- <transition :name="transitionName">
  <router-view></router-view>
</transition> -->
  <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
</keep-alive>
<router-view v-if="!$route.meta.keepAlive"></router-view>


    <!-- <router-view /> -->
  </div>
</template>

<script>


export default {


  data() {
    return {
      transitionName: "",
    }
  },
  watch: {
    //使用watch 监听$router的变化
    // $route(to, from) {
    //   //如果to索引大于from索引,判断为前进状态,反之则为后退状态
    //   console.log(to, "to");
    //   console.log(from, "from");
    //   if (to.meta.index > from.meta.index) {
    //     //设置动画名称
    //     this.transitionName = "";
    //   } else if (to.meta.index < from.meta.index){
    //     this.transitionName = "";
    //   }else
    //    this.transitionName = ''
    // },
  },

  created(){
    this.changeLanguage()
  },
  // 'zh-CN': require('./assets/lang/cn.js'),
  //     'en-US': require('./assets/lang/en.js'),
  //     'zh-HK': require('./assets/lang/hk.js'),
  //     'ca-CB': require('./assets/lang/ca.js'),
  //     'vi-VN': require('./assets/lang/vi.js'),
  //     'ja-JP': require('./assets/lang/jp.js'),
  //     'th-TH': require('./assets/lang/th.js'),
  //     'ko-KR': require('./assets/lang/co.js'),
  //     'ma-MA': require('./assets/lang/ma.js'),
  //     'in-IN': require('./assets/lang/in.js')
  methods: {

    changeLanguage() {
      // 处理本地化更改事件
    

    },
    // changeLanguage(){
    //     if (this.$i18n.locale == 'zh-CN') {
    //       this.$refs.app.style = 
    //     }
    //   },
  }
}
</script>

<style>
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif,"宋体";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  background-color: #151824;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #f8faff;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@keyframes flip-to-left {
    from {
        transform: perspective(1000px) rotateY(0);
    }
    to {
        transform: perspective(1000px) rotateY(-180deg);
    }
}

.slide-right-enter-active, .slide-right-leave-active, .slide-left-enter-active, .slide-left-leave-active {
      will-change: transform;
      background-color: #f8faff;
      transition: all 500ms;
      position: relative;
    }



    .slide-right-enter {
      opacity: 0;
      transform: rotateY(360deg);
    }

    .slide-right-leave-active {
      opacity: 0;
      transform: rotateY(-360deg);
    }

    .slide-left-enter {
      opacity: 0;
      transform: rotateY(360deg);
    }

    .slide-left-leave-active {
      opacity: 0;
      transform: rotateY(-360deg);
    }
</style>
